.page-container {
    opacity: 0; /* Make page-container fully transparent */
}

.player-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* Adjusted for spacing */
    padding: 5px 10px;
    background-color: #212020f2;
    width: 100%;
    position: fixed;
    bottom: -3px;
    height: 72px; /* Fixed height for landscape */
    min-height: 72px; /* Minimum height for consistency */
    font-family: 'Helvetica', 'Arial', sans-serif; /* Added Helvetica */
    z-index: 1000;
    /* box-sizing: border-box; */
}

.album-art {
    display: flex;
    align-items: center; /* Vertically centers the album art */
    justify-content: flex-start; /* Aligns album art to the left */
}

.album-art img {
    width: 65px; /* Default size for desktop */
    height: 65px; /* Ensure consistent sizing */
    object-fit: cover;
    border-radius: 5px;
    background-color: black; /* Ensures transparency shows up */
}

.metadata {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden; /* Prevents text from resizing elements */
    text-overflow: ellipsis; /* Adds an ellipsis to overflowing text */
    white-space: nowrap; /* Keeps the text on a single line */
    /* transform: translateY(-8px); */
    margin: 0.5rem 0; /* Add some vertical margin for spacing */
}

.title {
    color: white;
    margin: 4px;
    font-size: 16px; /* Adjusted font size for clarity */
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    /* New styles for truncation */
    max-width: 15ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.artist {
    color: #828181;
    margin: 4px;
    font-size: 15px; /* Adjusted font size for clarity */
    font-weight: 200;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    transform: translateY(-9px);
}

.controls {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.play-button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fe0000; /* Updated color for modern look? #4caf50 */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -33%); /* Adjusted to improve alignment */
    outline: none; /* Removes focus outline */
}

.play-button svg {
    width: 100%; /* Adjusted to avoid overflowing the button */
    height: auto;
    fill: white; /* Default play icon color */
}

.play-button.playing {
    background-color: #555151; /* Background color changes to grey when playing */
}

.play-button.playing svg {
    fill: red; /* Pause icon color for visibility */
}

.play-button.playing svg path {
    d: path("M6 4h4v12h-4zM14 4h4v12h-4z"); /* Pause icon path */
    transform: translate(0, 2px); /* Center pause icon vertically */
}

.track-progress {
    width: 40%; /* More space for progress bar */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align times and bar in a row */
    margin-top: 3px; /* Space below the play button */
    position: absolute;
    left: 50%;
    transform: translate(-50%, 24px);
}

.progress-bar {
    flex: 1; /* Make the bar flexible */
    height: 4px;
    margin: 0 10px;
    background-color: grey;
    transition: background-color 0.5s;
    accent-color: white;
    appearance: none;
}

.progress-bar::-webkit-slider-thumb {
    background-color: white;
}

.current-time, .remaining-time {
    font-size: 12px;
    color: #ccc;
}

.volume-control {
    position: absolute;
    top: 50%; /* Vertically centered */
    transform: translateY(-50%);
    right: 10px;
    display: flex;
    align-items: center;
    color: grey;
}

.volume-icon {
    font-size: 14px !important; /* Adjusted for balance */
    color: white !important;
    display: inline-block;
    margin-right: 5px;
    top: 50%; /* Vertically centered */
}

.volume-icon::before {
    content: '\f028'; /* Unicode for Font Awesome volume icon */
    font-family: 'FontAwesome'; /* Ensure the correct font is loaded */
    top: 50%; /* Vertically centered */
}

.volume-control input[type="range"] {
    width: 90px;
    height: 3px;
    background-color: grey;
    accent-color: white;
}

.react-player {
    width: 0px;
    height: 0px;
}

/* Responsive Styles for Mobile (Portrait) */
@media (max-width: 768px) and (orientation: portrait) {
    .player-container {
        flex-direction: column !important; /* Stack child elements vertically */
        align-items: center !important; /* Center-align the content */
        padding: 20px !important;
        height: 100% !important;
    }

    .album-art img {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 24px !important;
    }

    .metadata {
        text-align: center !important; /* Center text for metadata */
        margin-bottom: 10px !important;
        font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    }

    .title {
        color: white;
        margin: 2px;
        font-size: 32px; /* Adjusted font size for clarity */
        font-weight: 400;
        /* Ensure truncation still applies if needed */
        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .artist {
        color: #828181;
        margin: 2px;
        font-size: 26px; /* Adjusted font size for clarity */
        font-weight: 200;
        font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
        align-self: center;
    }

    .controls {
        flex-direction: row !important; /* Keep play and volume controls in a row */
        justify-content: space-around !important;
        width: 100% !important;
    }

    .play-button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -12%); /* Adjusted to improve alignment */
        outline: none; /* Removes focus outline */
    }

    .track-progress {
        flex-direction: row; /* Ensure items remain in a row */
        justify-content: center;
        margin-bottom: 10px;
        width: 60%;
    }

    .progress-bar {
        width: 90% !important; /* Fit the progress bar to screen */
        margin-bottom: 10px !important;
        transform: translateY(64px);
    }

    .current-time, .remaining-time {
        font-size: 12px;
        color: #ccc;
        transform: translateY(60px);
    }

    .volume-control {
        display: none;
        text-align: center !important;
        margin-top: 10px !important;
    }
}

/* New styles for like/dislike buttons */
.like-buttons {
    margin-top: 0.5rem;
}

.thumbs-up, .thumbs-down {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: grey; /* Neutral gray for default (unselected) */
}

.thumbs-up.active, .thumbs-down.active {
    color: gold;
}
