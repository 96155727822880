.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111; /* deep black */
  color: white;
  padding: 0.5rem 2rem;
  position: relative;
  height: 96px;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.header .logo {
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 160px;
}

.header .center ul {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.header .center a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding-bottom: 4px;
  position: relative;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.header .center a:hover,
.header .center a.active {
  border-bottom: 2px solid red; /* Accent under current tab */
}

.header .right {
  width: 150px;
  display: flex;
  align-items: center;
}

.header .right i {
  color: white;
  font-size: 20px;
  margin-left: 15px;
}

.btIconWidget {
  display: inline-block;
  text-decoration: none;
  color: white;
  margin-left: 15px;
}

.btIconWidgetIcon {
  display: inline-block;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
}

/* Login form */
.header form {
  position: absolute;
  top: 0;
  right: 0;
  background: #222;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
}

/* Hide input fields initially 
.header input[type="text"],
.header input[type="password"] {
  display: none !important;
  visibility: hidden !important;
  color: #111; /* deep black }*/

/* Placeholder styling */
.header input[type="text"]::placeholder,
.header input[type="password"]::placeholder {
  color: #111; /* deep black */
}

/* Manual overrides for right-side links */
.header .right a[href="/account"] {
  background: azure;
}

.header .right a[href*="instagram"] {
  background: #ff0000;
}

.header .right a[href*="twitter"] {
  background: beige;
}
