/* General body styles */
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

/* Root container styles */
#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Keeps player at the bottom */
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 0;
  margin: 0 auto;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  width: 100%;
}

/* Landscape orientation */
@media (orientation: landscape) {
  #root {
    background-image: url('./assets/images/Shaq-Fu-Radio-Shaquille-Oneil-Hip-Hop_Radio-station-1-2000x1024.png');
  }
}

/* Portrait orientation */
@media (orientation: portrait) {
  #root {
    background-image: url('./assets/images/ShaqFu-Radio-Background-Portrait.jpg');
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
